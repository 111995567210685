import React, { useState } from "react";
import axios from "axios";

import "../styles/centered-box.less";
import "../styles/form3-address.css";

import Geosuggest from "react-geosuggest";

import { Layout, Row, Col, Form, Button, Input, message } from "antd";

import { getLocalFormValues, navigate } from "../utils";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

function submitAddress(newFormValues, setIsLoading) {
  const { request_id: requestId } = newFormValues;
  setIsLoading(true);
  localStorage.setItem(requestId, JSON.stringify(newFormValues));
  if (!isValidPostalCode(newFormValues.postal_code)) {
    return navigate(`/adresse-invalide#${requestId}`);
  }
  axios
    .post(process.env.GATSBY_API_URL + "/update-registration", newFormValues)
    .then(() => {
      navigate(`/offres#${requestId}`);
    })
    .catch((e) => {
      message.error("Erreur lors de la communication : " + e.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
}

function parseGmapAddress(selectedAddress) {
  if (selectedAddress && selectedAddress.gmaps) {
    const {
      gmaps: { address_components, formatted_address: full_address },
    } = selectedAddress;
    return address_components.reduce(
      (prev, current) => ({
        ...prev,
        ...(current.types.includes("street_number") && {
          street_number: current.long_name,
        }),
        ...(current.types.includes("route") && {
          route: current.long_name,
        }),
        ...(current.types.includes("postal_code") && {
          postal_code: current.long_name,
        }),
        ...(current.types.includes("locality") && {
          locality: current.long_name,
        }),
      }),
      { full_address }
    );
  }
  return {};
}

function isValidPostalCode(zip) {
  if (
    zip.slice(0, 2) === "20" ||
    zip.slice(0, 2) === "97" ||
    zip.slice(0, 2) === "98"
  ) {
    // corse interdite
    // DOM interdite
    return false;
  }
  return true;
}

export default function AddressForm() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hasBlured, setBlured] = useState(false);
  const [showCustomAddressForm, setShowCustomAddressForm] = useState(false);
  const [validatedGmapAddress, setValidatedGmapAddress] = useState({});
  const initFormValues = getLocalFormValues();

  if (!initFormValues) {
    /* clear of the localStorage + direct access to URL => go home */
    navigate("/");
    return null;
  }

  const {
    firstname,
    full_address,
    street_number,
    route,
    postal_code,
    locality,
  } = initFormValues;

  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h1>{`Merci ${firstname}`}</h1>
            <h3>
              Pour qu'on calcule le prix le plus serré, dites nous ou vous
              habitez - promis on garde ça pour nous
            </h3>
            <Row type="flex" justify="center">
              <Col xl={12} md={18} xs={24}>
                {showCustomAddressForm ? (
                  <Form
                    form={form}
                    onFinish={(values) =>
                      submitAddress(
                        {
                          ...initFormValues,
                          ...values,
                          full_address: `${values.street_number} ${values.route} ${values.postal_code} ${values.locality}`,
                        },
                        setIsLoading
                      )
                    }
                    layout="vertical"
                  >
                    <Row gutter={16}>
                      <Col span={6}>
                        <Form.Item
                          name="street_number"
                          initialValue={street_number}
                          rules={[
                            {
                              required: true,
                              message: "Requis",
                            },
                          ]}
                          label="Numéro"
                        >
                          <Input disabled={isLoading} type="text" />
                        </Form.Item>
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          name="route"
                          initialValue={route}
                          rules={[
                            {
                              required: true,
                              message: "Requis",
                            },
                          ]}
                          label="Rue"
                        >
                          <Input disabled={isLoading} type="text" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="postal_code"
                          initialValue={postal_code}
                          rules={[
                            {
                              required: true,
                              message: "Requis",
                            },
                            {
                              pattern: /[0-9]{4,5}/,
                              message: "Format:NNNNN",
                            },
                          ]}
                          label="Code postal"
                        >
                          <Input disabled={isLoading} type="text" />
                        </Form.Item>
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          name="locality"
                          initialValue={locality}
                          rules={[{ required: true, message: "Requis" }]}
                          label="Ville"
                        >
                          <Input disabled={isLoading} type="text" />
                        </Form.Item>
                      </Col>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Étape suivante
                      </Button>
                    </Row>
                  </Form>
                ) : (
                  <div>
                    <Geosuggest
                      onBlur={() => setBlured(true)}
                      onSuggestSelect={(addr) =>
                        setValidatedGmapAddress(parseGmapAddress(addr))
                      }
                      country="fr"
                      types={["address"]}
                      placeholder="Rechercher une adresse..."
                      initialValue={full_address}
                      disabled={isLoading}
                    />
                    {hasBlured && (
                      <Button
                        onClick={() => setShowCustomAddressForm(true)}
                        type="link"
                      >
                        Renseigner une adresse non reconnue
                      </Button>
                    )}
                    <br />

                    <Button
                      style={{ marginTop: 30, marginRight: 15 }}
                      onClick={() => navigate(-1)}
                      disabled={isLoading}
                    >
                      Retour
                    </Button>

                    <Button
                      type="primary"
                      disabled={!Object.keys(validatedGmapAddress)}
                      size="large"
                      onClick={() =>
                        submitAddress(
                          {
                            ...initFormValues,
                            ...validatedGmapAddress,
                          },
                          setIsLoading
                        )
                      }
                      loading={isLoading}
                    >
                      Plus qu'un clic
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
